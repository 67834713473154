import './Footer.css';

function Footer() {
  return (
    <div>
      <footer className='footer'>
        <p className='footer__text'>Copyright © BertDev 2023</p>
      </footer>
    </div>
  );
}

export default Footer;
